.logo {
    margin-left: 50px;
    margin-top: 5px;
    width: 30px;
    /* Ширина */
}

.login-button {
    background-color: #C9B8A8;
    margin-right: 50px;
    margin-left: 10px;
}


.logo-name {
    font-family: 'Roboto', sans-serif;
    font-size: larger;
    margin-left: 5px;
}


.right-header {
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-width: 0;
}

.body-container {
    background-color: #97b288;
    display: flex;
    flex-direction: row;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    align-items: center;
}

.large-text {
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    width: 60%;
    font-weight: bold;
    display: flex;
}

.small-text {
    margin-top: 30px;
    margin-bottom: 30px;
}

.display-logo {
    width: 70%;
}

.left-body {
    width: 130%;
}

.right-body {
    width: 100%;
    display: flex;
    justify-content: center;
}

.question-button {
    font-weight: bold !important;
    font-size: 24px !important;
    padding: 0px 10px !important;
    margin-right: 40px;
}

.contact-header {
    display: flex;
    justify-content: center;
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
}

.link {
    text-decoration: none;
    color: rgb(42, 40, 40);
}

.contacts-container {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 50px;
}

.contact-unit {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    margin-bottom: 70px;
}

.contacts-content {
    display: flex;
    width: 100%;
}

.contact-side {
    width: 50%;
}

.buttons {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

h1 {
    font-family: 'Roboto', sans-serif;
}

table {
    border-collapse: collapse;
    border-radius: 20px;
}

th, td {
    border: 1px solid rgba(0, 0, 0, 0.322);
}